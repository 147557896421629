<script setup lang="ts">
import type { AsyncDataRequestStatus } from "nuxt/app"
import { hasLastPage, type Meta } from "~ui/types/paginate_meta"

defineOptions({
  inheritAttrs: false,
})

const { devState } = useDeveloperTools()

const { meta } = defineProps<{
  status: AsyncDataRequestStatus
  meta?: Meta
  error: unknown
  empty: boolean
  /** Whether the table has data. Used for loading state when data in table is updated.
   * For example if sorting is changed, `status` will be `pending` but we can still display data
   * instead of a skeleton
   */
  hasData: boolean
}>()
</script>
<template>
  <div class="relative w-full">
    <div
      v-if="status == 'pending' && hasData"
      class="absolute z-10 h-full w-full bg-white/50"
    >
      <div class="flex items-center justify-center py-24">
        <AnimationsLoadingSpinner />
      </div>
    </div>
    <table
      :class="`relative w-full divide-y divide-slate-200 overflow-hidden ${$attrs.class}`"
    >
      <thead v-if="$slots.head" class="relative z-20 bg-slate-50">
        <slot name="head" />
      </thead>
      <tbody v-if="empty || devState.empty">
        <tr>
          <td class="py-8" colspan="100">
            <slot name="empty" />
          </td>
        </tr>
      </tbody>
      <template
        v-else-if="
          status == 'success' ||
          status == 'idle' ||
          (status == 'pending' && hasData)
        "
      >
        <tbody>
          <slot />
        </tbody>
      </template>
      <tbody v-else-if="status == 'pending'">
        <slot name="loading" />
      </tbody>
      <tbody v-else-if="status == 'error'">
        <tr>
          <td class="py-8" colspan="100">
            <slot name="error">
              <div class="flex flex-col items-center">
                <SvgoPikaWarning class="size-16" />
                <TypographyHeading :level="3"> Error </TypographyHeading>
                <span class="mt-2 w-1/3 text-center text-sm text-slate-400"
                  >An unknown error occurred.</span
                >
              </div>
            </slot>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="meta && hasLastPage(meta) && meta.total > meta.per_page">
        <TablePagination :meta="meta!" />
      </tfoot>
    </table>
  </div>
</template>
