import type { WithRequired } from "./with_required"

export type Link = {
  url: string | null
  label: string
  active: boolean
}

export type Meta = {
  current_page: number
  from: number
  last_page?: number
  links: Link[]
  path: string
  per_page: number
  to: number
  total: number
}

export type PaginationResponse<T> = {
  data: T[]
  links: {
    first?: string | null
    last?: string | null
    prev?: string | null
    next?: string | null
  }
  meta: Meta
}

export function hasLastPage(
  meta: Meta,
): meta is WithRequired<Meta, "last_page"> {
  return meta.last_page != undefined
}
