<script setup lang="ts">
import type { Meta } from "~ui/types/paginate_meta"
import type { WithRequired } from "~ui/types/with_required"

const { meta } = defineProps<{
  meta: WithRequired<Meta, "last_page">
}>()
const hasPreviousPage = computed(() => page.value > 1)
const hasNextPage = computed(() => page.value < meta.last_page)

const route = useRoute()
const page = computed(() => Number(route.query.page ?? 1))
</script>
<template>
  <tr>
    <td colspan="100">
      <div class="flex items-center justify-between px-4 py-3 sm:px-6">
        <div class="flex w-full flex-1 items-center justify-between">
          <div>
            <p class="text-sm text-slate-700">
              <AnimatedPagination :page :last-page="meta.last_page" />
              <slot />
            </p>
          </div>
          <div>
            <nav class="pagination" aria-label="Pagination">
              <NuxtLink
                :to="`${route.path}?page=${page - 1}`"
                data-testid="pagination_previous"
              >
                <button
                  :disabled="!hasPreviousPage"
                  class="inline-flex cursor-pointer items-center rounded-l-md border border-slate-300 bg-white px-2 py-2 text-sm font-medium text-slate-500 hover:bg-slate-50 disabled:cursor-not-allowed disabled:opacity-50"
                >
                  <span class="sr-only">Previous</span>
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </NuxtLink>
              <NuxtLink
                :to="`${route.path}?page=${page + 1}`"
                data-testid="pagination_next"
              >
                <button
                  :disabled="!hasNextPage"
                  class="inline-flex cursor-pointer items-center rounded-r-md border border-slate-300 bg-white px-2 py-2 text-sm font-medium text-slate-500 hover:bg-slate-50 disabled:cursor-not-allowed disabled:opacity-50"
                >
                  <span class="sr-only">Next</span>
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </NuxtLink>
            </nav>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>
