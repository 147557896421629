<script setup lang="ts">
const props = defineProps<{ sortable?: boolean; sortKey: string }>()

type SortColumn = { key: string; direction: "asc" | "desc" }
const sortColumn = defineModel<SortColumn>()
</script>
<template>
  <th class="px-2 py-1 text-left text-xs font-medium">
    <button
      v-if="sortable"
      class="inline-flex cursor-pointer items-center border border-transparent px-2 py-1 hover:rounded-sm hover:border-slate-200 hover:bg-slate-100"
      @click="
        () => {
          if (sortColumn?.key == props.sortKey) {
            sortColumn = {
              key: props.sortKey,
              direction: sortColumn?.direction == 'desc' ? 'asc' : 'desc',
            }
          } else {
            sortColumn = {
              key: props.sortKey,
              direction: 'desc',
            }
          }
        }
      "
    >
      <slot />
      <svg
        class="ml-2 w-1.5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8 14"
      >
        <g id="sortable-icon" fill="none" fill-rule="evenodd">
          <path
            class="fill-current"
            :class="
              sortColumn?.direction == 'asc' && sortKey == sortColumn.key
                ? 'text-slate-900'
                : 'text-slate-400'
            "
            d="M1.70710678 4.70710678c-.39052429.39052429-1.02368927.39052429-1.41421356 0-.3905243-.39052429-.3905243-1.02368927 0-1.41421356l3-3c.39052429-.3905243 1.02368927-.3905243 1.41421356 0l3 3c.39052429.39052429.39052429 1.02368927 0 1.41421356-.39052429.39052429-1.02368927.39052429-1.41421356 0L4 2.41421356 1.70710678 4.70710678z"
          />
          <path
            class="fill-current"
            :class="
              sortColumn?.direction == 'desc' && sortKey == sortColumn.key
                ? 'text-slate-900'
                : 'text-slate-400'
            "
            fill-rule="nonzero"
            d="M6.29289322 9.29289322c.39052429-.39052429 1.02368927-.39052429 1.41421356 0 .39052429.39052429.39052429 1.02368928 0 1.41421358l-3 3c-.39052429.3905243-1.02368927.3905243-1.41421356 0l-3-3c-.3905243-.3905243-.3905243-1.02368929 0-1.41421358.3905243-.39052429 1.02368927-.39052429 1.41421356 0L4 11.5857864l2.29289322-2.29289318z"
          />
        </g>
      </svg>
    </button>
    <span
      v-else
      class="inline-flex items-center border border-transparent px-2 py-1"
    >
      <slot />
    </span>
  </th>
</template>
